import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./styles.css";


import logo from "../../../../assets/images/logo.png";
import capa from "../../../../assets/images/capa_segunda_via.jpg";
import favicon from "../../../../assets/images/favicon.ico";
import linkAndroid from "../../../../assets/images/google-play-badge.png"
import linkIos from "../../../../assets/images/app-store-badge.png"
import "moment/locale/pt-br";
import {defaultAuthenticator} from "../../../../utils/api"

import {
  Timeline,
  Button,
  Radio,
  Form,
  Table,
  Modal,
  Input,
  Row,
  Col,
  Alert,
} from "antd";
import InputMask from 'react-input-mask';


import ReCAPTCHA from "react-google-recaptcha";

import {
  WhatsAppOutlined,
  LeftOutlined,
  MailOutlined,
  CheckCircleOutlined,
  RightOutlined,
  InstagramOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import {
  Telephone,
  Facebook,
  Instagram,
  Youtube,
  Linkedin,
} from "react-bootstrap-icons";

import { FaRegDotCircle, FaRegCheckCircle } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";

import { duplicateBilletApi } from "../../../../services/duplicateBillet";
import { clientBoletosApi } from "../../../../services/boletosCLient";

import { isMobile } from "../../../../utils/isMobile";

import loadingSVG from "../../../../assets/images/loading.svg";
import moment from "moment";
import { instanceApi } from "../../../../utils/apiProtheus";
import { FormOfPaymentWarning } from "./components/FromOfPaymentWarning";
import { generateBillet } from "../../../../services/protheusService";

const statesTimeline = [
  {
    key: 1,
    value: "Informe seu CPF",
    color: "#F19245",
    icon: <FaRegDotCircle color="#71BF43" size={20} />,
  },
  {
    key: 2,
    value: "Escolha o contrato",
    color: "#F19245",
    icon: "",
  },
  {
    key: 3,
    value: "Visualize os boletos em aberto",
    color: "#F19245",
    icon: "",
  },
  {
    key: 4,
    value: "Faça a emissão do boleto desejado",
    color: "#F19245",
    icon: "",
  },
];

function DuplicateBillet() {
  const [states, setStates] = useState(1);
  const [stateNow, setStateNow] = useState(1);
  const [text, setText] = useState("Informe o CPF do titular:");
  const [placeholder, setPlaceholder] = useState("000.000.000-00");
  const [mask, setMask] = useState("000.000.000-00");
  const [isCpfInput, setIsCpfInput] = useState(true);
  const [inputCPF, setInputCPF] = useState("");
  const [inputBirthday, setInputBirthday] = useState("");
  const [queryButton, setQueryButton] = useState(true);
  const [recaptcha, setRecaptcha] = useState("");
  const [dataContract, setDataContract] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState("");
  const [client, setClient] = useState("");
  const [quantityInstallments, setQuantityInstallments] = useState(0);
  const [valueInstallments, setValueInstallments] = useState(0);
  const [dataParcelas, setDataParcelas] = useState([]);
  const [date, setDate] = useState('--/--/--');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSecondaryModalVisible, setIsSecondaryModalVisible] = useState(false);
  const [screenModal, setScreenModal] = useState(0);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [objectsSelected, setObjectsSelected] = useState([]);
  const [sendButton, setSendButton] = useState(true);
  const [contractCli, setContractCli] = useState({});
  const [pathToFiles, setPathToFiles] = useState([]);
  const [valueRadioButton, setValueRadioButton] = useState(1);
  const [emailClient, setEmailClient] = useState("");
  const [errorReprogram, setErrorReprogram] = useState("");
  const [form] = Form.useForm();
  
  
  useEffect(() => {
    form.resetFields();
  }, [text]);
  
  useEffect(() => {
    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    let validationCPF = inputCPF === "" || inputCPF === undefined || inputCPF === null || !cpfRegex.test(inputCPF);
    let validationBirthday = inputBirthday === "" || inputBirthday === undefined || inputBirthday === null || !moment(inputBirthday, "DD/MM/YYYY").isValid() || moment(inputBirthday, "DD/MM/YYYY") > moment();
    
    if (validationCPF || !recaptcha || validationBirthday) {
      setQueryButton(true);
    } else {
      setQueryButton(false);
    }
  }, [inputCPF, recaptcha, inputBirthday]);

  useEffect(() => {
    if (rowsSelected.length !== 0) {
      setSendButton(false);
    } else {
      setSendButton(true);
    }
  }, [rowsSelected]);

  useEffect(() => {
    if (screenModal === 1) handleOk();
  }, [screenModal]);

  const formtDate = (data) => {
    const date = new Date(`${data.replace(" ", "T")}:00`);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formatted = `${day}/${month}/${year}`;

    return formatted;
  };

  const rowSelection = {
    selectedRowKeys: rowsSelected,
    onChange: (selectedRowKeys, selectedRows) => {
      setRowsSelected(selectedRowKeys);
      setObjectsSelected(selectedRows);
    },
  };

  const sortData = (data) => {
    return data.sort((a, b) => {
      return (
        new Date(a.VENCORIG).getTime() - new Date(b.VENCORIG).getTime()
      );
    });
  };

  const onChangeReCAPTCHA = (value) => {
    if (value) {
      setRecaptcha(value);
    }
  };

  const getBillets = (value) => {
    setLoading(true);
    setContractCli(value);

    duplicateBilletApi
      .getBillet(value.CONTRATO, value.TIPOCONTRT, {
        headers: {
          tenantId: `${value.EMPRESA},${value.FILIAL}`,
        },
      })
      .then((response) => {
        setClient(value.CLIENTE);
        setContract(value.CONTRATO_EMPRESA);
        if (!response) {
          setStates(6);
        } else {
          setDataParcelas(response);
          Promise.all(
            response.map((value) => {
              // dateNow.getTime() > new Date(value.VENCIMENTO).getTime()
              if (
                moment() >
                moment(value.VENCORIG, "YYYY-MM-DD HH:mm").set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                })
              ) {
                return {
                  ...value,
                  value: value.VLRTOTAL,
                };
              }
            })
          ).then((response) => {
            if (!response) {
              setStates(5);
              setLoading(false);
              return false;
            }

            const data = response
              .filter((value) => {
                if (value && value.value) {
                  return value;
                }
              })
              .map((value) => {
                return {
                  ...value,
                  value: value.value.trim().replace(",", "."),
                };
              });

            const totalValue = data.reduce(
              (total, value) =>
                total +
                parseFloat(
                  value.VLRTOTAL.trim().replace(".", "").replace(",", ".")
                ),
              0
            );

            setQuantityInstallments(data.length);
            setValueInstallments(totalValue.toFixed(2).replace(".", ","));

            if (totalValue === 0) {
              setStates(5);
            } else {
              setStates(4);
            }
          });
        }

        setStateNow(3);
        setLoading(false);
      })
      .catch((err) => {
        setStateNow(7);
        setLoading(false);
      });

    setStates(4);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const valueFinal = inputCPF;
    const birthday = new String(
      moment(inputBirthday, "DD/MM/YYYY").format("YYYYMMDD")
    );

    await defaultAuthenticator();

    let serpro = await clientBoletosApi.getSerpro(valueFinal.toUpperCase().replace(/[^a-zA-Z0-9]/g, ""))


    if (serpro.nascimento != moment(inputBirthday, "DD/MM/YYYY").format("DDMMYYYY")) {
      setStates(9);
      setStateNow(9);
      setLoading(false);
      return false;
    }
    duplicateBilletApi
      .listBol(valueFinal.toUpperCase().replace(/[^a-zA-Z0-9]/g, ""), {
        headers: {
          tenantId: `03,01`,
          Fields: "CONTRATO,UNIDADE,CLIENTE,RECORRENCIA,FORMA_PAGTO,PERMITE_FATDIG,TIPO,TIPOCONTRT,EMPRESA,FILIAL"
        },
      },
        birthday
      )
      .then((response) => {
        if (!response) {
          setStates(2);
          setStateNow(2);
          setLoading(false);
          return false;
        }

        setContractCli(response[0]);

        const data = response.map((value) => {
          return {
            ...value,
            CONTRATO_EMPRESA: `${value.CONTRATO} - ${value.UNIDADE}`,
          };
        });

        setDataContract(data);
        setStates(3);
        setStateNow(2);
        setLoading(false);
      })
      .catch(() => {
        setStates(7);
        setStateNow(2);
        setLoading(false);
      });
  };

  const columnsContract = [
    {
      title: "Contrato",
      dataIndex: "CONTRATO_EMPRESA",
      key: "CONTRATO_EMPRESA",
    },
    {
      title: "Titular",
      dataIndex: "CLIENTE",
      key: "CLIENTE",
    },
    {
      align: "right",
      render: (data) => (
        <Button
          style={{ background: "#017F3E", color: "#FFF", borderRadius: 5 }}
          onClick={() => getBillets(data)}
          loading={loading}
        >
          {loading ? <>&nbsp; AGUARDE...</> : "VER BOLETOS"}
        </Button>
      ),
    },
  ];

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(5);
  }

  /**
   * Faz as alterações necessárias na linha do tempo
   */
  const timeLine = () => {
    const newStatesTimeline = statesTimeline.map((value) => {
      if (value.key === stateNow) {
        return {
          ...value,
          icon: <FaRegDotCircle color="#71BF43" size={20} />,
        };
      } else if (value.key < stateNow) {
        return {
          ...value,
          icon: <FaRegCheckCircle color="#71BF43" size={20} />,
        };
      } else if (value.key > stateNow) {
        return {
          ...value,
        };
      }
    });

    return (
      <Timeline>
        {newStatesTimeline.map((value) => (
          <Timeline.Item
            color={value.color}
            dot={value.icon}
            key={value.key}
            className="timeline-item"
          >
            {value.value}
          </Timeline.Item>
        ))}
      </Timeline>
    );
  };

  const download = async () => {
    if (objectsSelected.length === 0) return false;

    try {
      setLoading(true);
      const billetsPromise = objectsSelected.map(async (billet) => {
        return {
          fileName: `${contract}_vencimento_${new Date(
            billet.VENCIMENTO
          ).toLocaleDateString()}_morada_da_paz`,
          base64: await generateBillet({
            contrato: billet.CONTRATO,
            numero: billet.NUMERO,
            parcela: billet.PARCELA,
            prefixo: billet.PREFIXO,
            tipo: billet.TIPO,
            tipoContrt: billet.TIPOCONTRT,
          }, 'base64',
            `${billet.EMPRESA},${billet.FILIAL}`)
        }

      })

      const billets = await Promise.all(billetsPromise)

      billets.forEach((billet) => {

        const linkSource = `data:application/pdf;base64,${billet.base64}`;
        const downloadLink = document.createElement("a");


        downloadLink.href = linkSource;
        downloadLink.download = billet.fileName;

        downloadLink.click();
      });

      const getParcelas = await duplicateBilletApi.getBillet(contractCli.CONTRATO, contractCli.TIPOCONTRT, {
        headers: {
          tenantId: `${contractCli.EMPRESA},${contractCli.FILIAL}`,
        },
      })

      setDataParcelas(getParcelas);

      setIsModalVisible(true);

      setScreenModal(1);


    } catch (err) {
      setIsModalVisible(false);
      setStates(8)
    } finally {
      setLoading(false)
    }

    return;

  };

  const openServices24h = () => {
    Modal.info({
      title: "Serviços disponíveis 24h",
      content: (
        <div>
          <p>
            Se você perdeu alguém e precisar dar início ao seu atendimento, estamos disponíveis 24h para lhe atender e realizar nossos serviços funerários.
            Basta ligar gratuitamente para nossa Central de Atendimento ao Cliente 24h, através do 0800 123 4848.
          </p>
          <p>
            Caso precise de ajuda para resolver questões menos urgentes, como atendimento administrativo e compras na floricultura,
            dê preferência a falar conosco no WhatsApp, através do número 0800 123 4848, de segunda-feira a sexta-feira, das 7h30 às 17h30, exceto em feriados.
          </p>
        </div>
      ),
      onOk() { },
    });
  };
    

  const sendMail = async ({ customerEmail }) => {

    try {
      setEmailClient(customerEmail);
      if (objectsSelected.length === 0) return false;

      setLoading(true);

      let values = await handleGenerateBillet(objectsSelected);


      const getParcelas = await duplicateBilletApi.getBillet(contractCli.CONTRATO, contractCli.TIPOCONTRT, {
        headers: {
          tenantId: `${contractCli.EMPRESA},${contractCli.FILIAL}`,
        },
      })

      setDataParcelas(getParcelas);

      setLoading(false)
      sendMailClient(customerEmail, values);

    } catch (err) {
      setIsModalVisible(false);
      setStates(8);
    }
  };

  const sendMailClient = (email, files) => {
    setLoading(true);
    setEmailClient(email);
    console.log(email)
    // console.log(files)
    clientBoletosApi
      .sendMail({
        files,
        email,
        client,
      })
      .then(() => {
        setLoading(false);
        setIsModalVisible(false);
        setIsSecondaryModalVisible(true);
      })
      .catch(() => {
        setStates(7);
        setLoading(false);
      });
  };

  const handleRadioButton = (e) => {
    setValueRadioButton(e.target.value);
  };

  /**
   * Renderiz a tabela com as parcelas a pagar
   */
  const tableRender = () => (
    <div>
      <Table
        dataSource={sortData(dataParcelas)}
        loading={loading}
        rowClassName={(record) => {
          return moment() >
            moment(record.VENCORIG, "YYYY-MM-DD HH:mm").set({
              hour: 23,
              minute: 59,
              second: 59,
            })
            ? "table-row-red"
            : "table-row-dark";
        }}
        size="small"
        rowKey={(record) =>
          `${record.PREFIXO}_${record.NUMERO}_${record.PARCELA}_${record.TIPO}`
        }
        rowSelection={
          contractCli.RECORRENCIA === true || contractCli.FORMA_PAGTO === "Cartão de Crédito" ? null :
            {
              ...rowSelection,
              checkStrictly: false,
              type: "checkbox",
            }}
        pagination={{
          current: page,
          pageSize: rowsPerPage,
          total: dataParcelas.length,
        }}
        onChange={handleTableChange}
        columns={[
          {
            title: "Vencimento Original",
            dataIndex: "VENCORIG",
            render: (data) => {
              return formtDate(data);
            },
          },
          {
            title: "Vencimento Atual",
            dataIndex: "VENCIMENTO",
            render: (data) => {
              return formtDate(data);
            },
          },
          {
            title: "Valor Atual",
            dataIndex: "VLRTOTAL",
            render: (data) => {
              return "R$ " + data;
            },
          },
        ]}
      />
      {
        contractCli.RECORRENCIA === true || contractCli.FORMA_PAGTO === "Cartão de Crédito" ?
          <Alert
            message={contractCli.RECORRENCIA === true ? "Seu contrato está na modalidade de pagamento em recorrência com cartão de crédito. As parcelas serão cobradas automaticamente no dia do vencimento."
              : "Seu contrato está na modalidade de pagamento em cartão de crédito."}
            type="warning"
            showIcon
            style={{ marginTop: 20 }}
          /> : <div className="buttons-mobile">
            <Button
              className="button-download"
              onClick={download}
              disabled={sendButton}
              loading={loading}
            >
              {loading ? (
                <>&nbsp; Aguarde...</>
              ) : (
                <>
                  <IoMdDownload size={13} />
                  &nbsp; <strong>BAIXAR SELECIONADOS</strong>
                </>
              )}
            </Button>
            <Button
              className="button-send-mail"
              onClick={() => {
                showModal(2);
                setIsModalVisible(true);
              }}
              disabled={sendButton}
              loading={loading}
            >
              {loading ? (
                <>
                  &nbsp; <strong>AGUARDE...</strong>
                </>
              ) : (
                <>
                  <MailOutlined /> &nbsp; <strong>ENVIAR POR E-MAIL</strong>
                </>
              )}
            </Button>
          </div>
      }
    </div>
  );

  /**
   * Gera as parcelas para o cliente, caso necessário será reprogramado
   */
  const handleGenerateBillet = async (billets) => {
    let counter = 0
    return Promise.all(
      billets.map(async (billet) => {
        if (!billet.PREFIXO || !billet.NUMERO || !billet.TIPO) {
          setLoading(false);
          return false;
        }

        return new Promise((resolve, reject) => {
          duplicateBilletApi
            .gerarParcela(
              {
                prefixo: billet.PREFIXO,
                numero: billet.NUMERO,
                parcela: billet.PARCELA,
                tipo: billet.TIPO,
                tipoContrt: billet.TIPOCONTRT,
                contrato: billet.CONTRATO,
              },
              {
                headers: {
                  tenantId: `${billet.EMPRESA},${billet.FILIAL}`,
                },
              }
            )
            .then((response) => {
              resolve({
                "base64": response,
                "name": `Vencimento ${new Date(
                  objectsSelected[counter].VENCIMENTO
                ).toLocaleDateString()} - Boleto Morada da Paz.pdf`
              });
              counter ++;
            })
            .catch(() => reject(false))
            .finally(() => {
            });
        });

      })
    ).then((response) => {
      return response.filter((value) => {
        if (value) {
          return value;
        }
      });
    });
  };


  /**
   * Função que define qual página será renderizada de acordo com o estado
   */
  const renderStatePage = () => {
    let html;

    // Busca de contrato
    if (states === 1) {
      html = (
        <>
          <h2 className="text-choice">CPF do titular:</h2>
          <div className="input-data">
            <Form name="form" onFinish={async () => { await handleSubmit() }} form={form}>
              <Form.Item
                name="valueCpf"
                rules={[
                  {
                    validator: (_, value) => {
                      // condição para evitar que o campo seja validado quando o usuário não preencheu o campo
                      const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
                      if (value === "" || value === undefined || value === null || !cpfRegex.test(value)) {
                        return Promise.reject("Por favor, insira um CPF válido.");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputMask
                  className="mask-input"
                  mask="999.999.999-99"
                  placeholder="000.000.000-00"
                  value={inputCPF}
                  onChange={(e) => setInputCPF(e.target.value)}
                />
              </Form.Item>
              <h2 className="text-choice">Data de Nascimento do titular:</h2>
              <Form.Item
                name="valueBirthday"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value === "" || value === undefined || value === null || !moment(value, "DD/MM/YYYY").isValid() || moment(value, "DD/MM/YYYY") > moment()) {
                        return Promise.reject("Por favor, insira uma data de nascimento válida.");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                >
                <InputMask
                  className="mask-input"
                  mask="99/99/9999"
                  placeholder="__/__/____"
                  value={inputBirthday}
                  onChange={(e) => setInputBirthday(e.target.value)}
                />
              </Form.Item>

              <div className="recaptcha-before">
                <ReCAPTCHA
                  className="recaptcha"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onChangeReCAPTCHA}
                  style={{ marginBottom: 10 }}
                />
              </div>

              <Button
                type="primary"
                size="large"
                className="button-search-color"
                htmlType="submit"
                style={{ borderRadius: 5, width: 303 }}
                disabled={queryButton}
                loading={loading}
              >
                {loading ? <>&nbsp; AGUARDE...</> : "CONSULTAR"}
              </Button>
            </Form>
          </div>
        </>
      );

      // Contrato não encontrado
    } else if (states === 2) {
      html = (
        <>
          <div className="content-not-found">
            <h1 className="title-not-found">
              Não encontramos <br /> nenhum contrato :(
            </h1>
            <ul style={{ marginBottom: 20 }} className="remove-ident">
              <li>
                Verifique se você informou os dados do titular corretamente.
              </li>
              <li>
                Se tiver alguma dúvida, entre em contato com nossa Central de
                Atendimento ao Cliente:
              </li>
            </ul>

            <div className="icons-mobile">
              <div className="icon-phone-mobile">
                <a href="tel:08001234848" className="icon-color">
                  <Telephone /> 0800 123 4848
                </a>{" "}
                (atendimento 24h, capitais e demais localidades)
              </div>

              <div className="icon-phone-mobile">
                <a href="https://wa.me/5508001234848" className="icon-color">
                  <WhatsAppOutlined /> 0800 123 4848
                </a>{" "}
                (segunda a sexta, 7h30 às 17h30, exceto feriados)
              </div>
            </div>
            <br />
            <h3
              className="icon-color-2 point-mouse return-screen size-return"
              onClick={() => {
                setStates(1);
                setStateNow(1);
              }}
            >
              {" "}
              <LeftOutlined /> Realizar nova consulta
            </h3>
          </div>
        </>
      );

      // Contratos encontrados
    } else if (states === 3) {
      html = (
        <>
          {isMobile() ? (
            <div className="header-table">
              <p
                className="icon-color-2 point-mouse size-return"
                onClick={() => {
                  setStates(1);
                  setStateNow(1);
                }}
              >
                {" "}
                <LeftOutlined /> Realizar nova consulta
              </p>
              <h2>Contratos encontrados</h2>
            </div>
          ) : (
            <div className="header-table">
              <h2>Contratos encontrados</h2>
              <p
                className="icon-color-2 point-mouse size-return"
                onClick={() => {
                  setStates(1);
                  setStateNow(1);
                }}
              >
                {" "}
                <LeftOutlined /> Realizar nova consulta
              </p>
            </div>
          )}
          <Table
            columns={columnsContract}
            dataSource={dataContract}
            size="small"
            pagination={false}
            rowKey={(record) => record.CONTRATO}
            className="table-results"
          />
        </>
      );

      // Boletos em aberto para cobrança
    } else if (states === 4) {
      html = (
        <>
          {isMobile() ? (
            <div className="header-table">
              <p
                className="icon-color-2 point-mouse size-return"
                onClick={() => {
                  setStates(1);
                  setStateNow(1);
                }}
              >
                {" "}
                <LeftOutlined /> Realizar nova consulta
              </p>
              <h2>Boletos em aberto</h2>
            </div>
          ) : (
            <div className="header-table">
              <h2>Boletos em aberto</h2>
              <p
                className="icon-color-2 point-mouse size-return"
                onClick={() => {
                  setStates(1);
                  setStateNow(1);
                }}
              >
                {" "}
                <LeftOutlined /> Realizar nova consulta
              </p>
            </div>
          )}
          <div className="screen-card-payment-is-open">
            <div className="header-contract">
              <Row>
                <Col span={6}>
                  <strong>Contrato:</strong>
                </Col>
                <Col span={18}>{contract}</Col>
              </Row>
              <Row>
                <Col span={6}>
                  <strong>Titular:</strong>
                </Col>
                <Col span={18}>{client}</Col>
              </Row>
            </div>
            <div className="header-plots-opened">
              <Row>
                <Col span={12}>
                  <strong>Boletos em atraso:</strong>
                </Col>
                <Col span={12}>{quantityInstallments}</Col>
              </Row>
              <Row>
                <Col span={12}>
                  <strong>Valor total em atraso:</strong>
                </Col>
                <Col span={12}>R$ {valueInstallments}</Col>
              </Row>
            </div>
          </div>
          <h3 className="title-screen title-screen-table-mobile">
            SELECIONE OS BOLETOS QUE VOCÊ DESEJA EMITIR:
          </h3>
          {tableRender()}
        </>
      );

      // boletos em aberto, mas nenhum vencido
    } else if (states === 5) {
      html = (
        <>
          {isMobile() ? (
            <div className="header-table">
              <p
                className="icon-color-2 point-mouse size-return"
                onClick={() => {
                  setStates(1);
                  setStateNow(1);
                }}
              >
                {" "}
                <LeftOutlined /> Realizar nova consulta
              </p>
              <h2>Boletos em aberto</h2>
            </div>
          ) : (
            <div className="header-table">
              <h2>Boletos em aberto</h2>
              <p
                className="icon-color-2 point-mouse size-return"
                onClick={() => {
                  setStates(1);
                  setStateNow(1);
                }}
              >
                {" "}
                <LeftOutlined /> Realizar nova consulta
              </p>
            </div>
          )}

          <FormOfPaymentWarning
            contract={{
              company: contractCli.EMPRESA,
              allowedFatdig: contractCli.PERMITE_FATDIG,
              installments: dataParcelas || [],
              recurrenceActive: contractCli.RECORRENCIA,
              contractActive: true, // Possível refatoração: adicionar campo de status do contrato ao REST. Atualmente a query retorna apenas contratos ativos.
              contractType: contractCli.TIPO,
            }}
          />

          <div className="screen-card-payment-is-open">
            <div className="header-contract">
              <Row>
                <Col span={6}>
                  <strong>Contrato:</strong>
                </Col>
                <Col span={18}>{contract}</Col>
              </Row>
              <Row>
                <Col span={6}>
                  <strong>Titular:</strong>
                </Col>
                <Col span={18}>{client}</Col>
              </Row>
            </div>
            <div className="header-plots-opened-success">
              Parabéns, você não tem nenhum
              <br /> boleto em atraso!
            </div>
          </div>
          <h3 className="title-screen title-screen-table-mobile">
            SELECIONE OS BOLETOS QUE VOCÊ DESEJA EMITIR:
          </h3>
          {tableRender()}
        </>
      );

      // Não há boletos em aberto
    } else if (states === 6) {
      html = (
        <div className="contract-not-found">
          <div>
            <h2>Boletos em aberto</h2>
          </div>

          <div className="header-contract">
            <Row>
              <Col span={6}>
                <strong>Contrato:</strong>
              </Col>
              <Col span={18}>{contract}</Col>
            </Row>
            <Row>
              <Col span={6}>
                <strong>Titular:</strong>
              </Col>
              <Col span={18}>{client}</Col>
            </Row>
          </div>

          <div>
            <h1 className="title-not-found">
              Não encontramos <br /> nenhum boleto em aberto :)
            </h1>
            <ul style={{ marginBottom: 20 }} className="remove-ident">
              <li>
                Se tiver alguma dúvida, entre em contato com nossa Central de
                Atendimento ao Cliente:
              </li>
            </ul>

            <div className="icons-mobile">
              <div className="icon-phone-mobile">
                <a href="tel:08001234848" className="icon-color">
                  <Telephone /> 0800 123 4848
                </a>{" "}
                (atendimento 24h, capitais e demais localidades)
              </div>

              <div className="icon-phone-mobile">
                <a href="https://wa.me/5508001234848" className="icon-color">
                  <WhatsAppOutlined /> 0800 123 4848
                </a>{" "}
                (segunda a sexta, 7h30 às 17h30, exceto feriados)
              </div>
            </div>
            <br />
            <h3
              className="icon-color-2 point-mouse return-screen size-return"
              onClick={() => {
                setStates(1);
                setStateNow(1);
              }}
            >
              {" "}
              <LeftOutlined /> Realizar nova consulta
            </h3>
          </div>
        </div>
      );

      // erro em qualquer consulta no servidor
    } else if (states === 7) {
      html = (
        <>
          <div className="screen-error">
            <h1 style={{ color: "red" }} className="title-screen">
              Ops... Parece que estamos enfrentando <br /> problemas em nossos
              servidores :(
            </h1>
            <ul style={{ marginBottom: 20 }} className="remove-ident">
              <li>Tente novamente em alguns instantes</li>
              <li>
                Se tiver alguma dúvida, entre em contato com nossa Central de
                Atendimento ao Cliente:
              </li>
            </ul>

            <div className="icons-mobile">
              <div className="icon-phone-mobile">
                <a href="tel:08001234848" className="icon-color">
                  <Telephone /> 0800 123 4848
                </a>{" "}
                (atendimento 24h, capitais e demais localidades)
              </div>

              <div className="icon-phone-mobile">
                <a href="https://wa.me/5508001234848" className="icon-color">
                  <WhatsAppOutlined /> 0800 123 4848
                </a>{" "}
                (segunda a sexta, 7h30 às 17h30, exceto feriados)
              </div>
            </div>
            <br />
            <h3
              className="icon-color-2 point-mouse return-screen size-return"
              onClick={() => {
                setStates(1);
                setStateNow(1);
              }}
            >
              {" "}
              <LeftOutlined /> Realizar nova consulta
            </h3>
          </div>
        </>
      );
      // Erro ao tentar imprimir o boleto
    } else if (states === 8) {
      html = (
        <>
          <div className="screen-error">
            <h1 className="title-screen">
              Ops... não foi possível <br />
              emitir o seu boleto :(
            </h1>
            <ul style={{ marginBottom: 20 }} className="remove-ident">
              <li>
                Fomos notificados do seu problema e entraremos em contato o mais
                breve possível para resolvê-lo.
              </li>
              <li>
                Se preferir, você também pode falar com a nossa Central de
                Atendimento ao Cliente:
              </li>
            </ul>

            <div className="icons-mobile">
              <div className="icon-phone-mobile">
                <a href="tel:08001234848" className="icon-color">
                  <Telephone /> 0800 123 4848
                </a>{" "}
                (atendimento 24h, capitais e demais localidades)
              </div>

              <div className="icon-phone-mobile">
                <a href="https://wa.me/5508001234848" className="icon-color">
                  <WhatsAppOutlined /> 0800 123 4848
                </a>{" "}
                (segunda a sexta, 7h30 às 17h30, exceto feriados)
              </div>
            </div>
            <br />
            <h3
              className="icon-color-2 point-mouse return-screen size-return"
              onClick={() => {
                setStates(1);
                setStateNow(1);
              }}
            >
              {" "}
              <LeftOutlined /> Realizar nova consulta
            </h3>
          </div>
        </>
      );
    } else if (states === 9) {
      //State da validação da serpro
      html = (
        <>
          <div className="content-not-found">
            <h1 className="title-not-found">
            O CPF e a Data de Nascimento<br /> informados não conferem!
            </h1>
            <ul style={{ marginBottom: 20 }} className="remove-ident">
              <li>
              Verifique se você informou o CPF e Data de Nascimento do titular corretamente.
              </li>
              <li>
                Se tiver alguma dúvida, entre em contato com nossa Central de
                Atendimento ao Cliente:
              </li>
            </ul>

            <div className="icons-mobile">
              <div className="icon-phone-mobile">
                <a href="tel:08001234848" className="icon-color">
                  <Telephone /> 0800 123 4848
                </a>{" "}
                (atendimento 24h, capitais e demais localidades)
              </div>

              <div className="icon-phone-mobile">
                <a href="https://wa.me/5508001234848" className="icon-color">
                  <WhatsAppOutlined /> 0800 123 4848
                </a>{" "}
                (segunda a sexta, 7h30 às 17h30, exceto feriados)
              </div>
            </div>
            <br />
            <h3
              className="icon-color-2 point-mouse return-screen size-return"
              onClick={() => {
                setStates(1);
                setStateNow(1);
              }}
            >
              {" "}
              <LeftOutlined /> Tentar novamente
            </h3>
          </div>
        </>
      );

    }

    return html;
  };

  /**
   * Função para renderiazar os icones no cabeçalho ou no rodapé
   */
  const renderIconsSocialMedia = () => {
    return (
      <div className="icons-media-social">
        <a
          href="https://www.instagram.com/moradadapaz"
          target="_blank"
          rel="noreferrer"
        >
          <Instagram className="icon-size icon-color icon-one-margin" />
        </a>

        <a
          href="https://www.facebook.com/moradadapaz"
          target="_blank"
          rel="noreferrer"
        >
          <Facebook className="icon-size icon-color icon-two-margin" />
        </a>

        <a
          href="https://www.linkedin.com/company/morada-da-paz/"
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin className="icon-size icon-color icon-two-margin" />
        </a>

        <a
          href="https://www.youtube.com/channel/UCsgr7XQKi6pFHAmt6HZ5sAA"
          target="_blank"
          rel="noreferrer"
        >
          <Youtube className="icon-size icon-color icon-two-margin" />
        </a>
      </div>
    );
  };

  const renderIconsSocialMediaModal = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <a
          href="https://www.instagram.com/moradadapaz"
          target="_blank"
          rel="noreferrer"
        >
          <Instagram className="icon-size icon-color" />
        </a>

        <a
          href="https://www.facebook.com/moradadapaz"
          target="_blank"
          rel="noreferrer"
        >
          <Facebook className="icon-size icon-color icon-two-margin-modal" />
        </a>

        <a
          href="https://www.linkedin.com/company/morada-da-paz/"
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin className="icon-size icon-color icon-two-margin-modal" />
        </a>

        <a
          href="https://www.youtube.com/channel/UCsgr7XQKi6pFHAmt6HZ5sAA"
          target="_blank"
          rel="noreferrer"
        >
          <Youtube className="icon-size icon-color icon-two-margin-modal" />
        </a>
      </div>
    );
  };

  const renderSubMenuHeader = () => {
    return (
      <>
        <div className="icone-phone-1">
          <span>Central de Atendimento 24h:&nbsp;&nbsp;&nbsp;</span>
          <a
            className="icon-color"
            href="tel:08001234848"
            target="_blank"
            rel="noreferrer"
          >
            <Telephone /> 0800 123 4848
          </a>
        </div>

        <div className="icone-phone-2">
          <a
            className="icon-color"
            href="https://wa.me/5508001234848"
            target="_blank"
            rel="noreferrer"
          >
            <WhatsAppOutlined /> Fale no WhatsApp
          </a>
        </div>
      </>
    );
  };

  /**
   * Função para renderizar o footer
   */
  const renderFooter = () => {
    return (
      <>
        <Row>
          <Col md={8} xs={24}>
            <h3 style={{ marginBottom: 15 }}>
              <RightOutlined className="icon-color" /> Central de Atendimento
              24h
              <br />
              <big>
                <big>&nbsp;&nbsp;&nbsp;0800 123 4848</big>
              </big>
            </h3>
            <Button
              href="tel:08001234848"
              className="button-download"
              size="large"
              style={{ borderRadius: 5, width: "70%", marginBottom: 10 }}
            >
              <Telephone />
              &nbsp;&nbsp;<strong>Ligue agora</strong>
            </Button>
            <Button
              href="https://wa.me/5508001234848"
              className="button-download"
              size="large"
              style={{ borderRadius: 5, width: "70%", marginBottom: 10 }}
            >
              <WhatsAppOutlined />
              &nbsp;&nbsp;<strong>Fale no WhatsApp</strong>
            </Button>
            <p
              style={{ marginBottom: 30 }}
              onClick={openServices24h}
              className="icon-color point-mouse"
            >
              <InfoCircleOutlined /> Serviços disponíveis 24h
            </p>
            <h3 style={{ marginBottom: 15 }}>
              <RightOutlined className="icon-color" /> E-mail
            </h3>
            <p style={{ marginBottom: 30 }}>
              <a
                className="icon-color"
                href="mailto:contato@moradadapaz.com.br"
                target="_blank"
                rel="noreferrer"
              >
                <MailOutlined /> contato@moradadapaz.com.br
              </a>
            </p>
          </Col>
          <Col md={8} xs={24}>
            <h3 style={{ marginBottom: 15 }}>
              <RightOutlined className="icon-color" /> Nossas redes sociais
            </h3>
            <p>
              <a
                className="icon-color"
                href="https://www.instagram.com/moradadapaz"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramOutlined /> @moradadapaz
              </a>
            </p>
            <p>
              <a
                className="icon-color"
                href="https://www.facebook.com/moradadapaz"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookOutlined /> @moradadapaz
              </a>
            </p>
            <p>
              <a
                className="icon-color"
                href="https://www.linkedin.com/company/morada-da-paz/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinOutlined /> @moradadapaz
              </a>
            </p>
            <p style={{ marginBottom: 30 }}>
              <a
                className="icon-color"
                href="https://www.youtube.com/channel/UCsgr7XQKi6pFHAmt6HZ5sAA"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeOutlined /> @moradadapaz
              </a>
            </p>
          </Col>
          <Col md={8} xs={24}>
            <h3 style={{ marginBottom: 15 }}>
              <RightOutlined className="icon-color" /> O Morada da Paz
            </h3>
            <p>
              <a
                className="icon-color"
                href="https://www.moradadapaz.com.br"
                target="_blank"
                rel="noreferrer"
              >
                Sobre o Morada da Paz
              </a>
            </p>
            <p>
              <a
                className="icon-color"
                href="https://www.moradadamemoria.com.br"
                target="_blank"
                rel="noreferrer"
              >
                Morada da Memória
              </a>
            </p>
            <p>
              <a
                className="icon-color"
                href="https://moradadocuidado.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                Morada do Cuidado
              </a>
            </p>
            <p>
              <a
                className="icon-color"
                href="https://moradadapaz.com.br/funerariadigital/"
                target="_blank"
                rel="noreferrer"
              >
                Funerária Digital
              </a>
            </p>
            <p>
              <a
                className="icon-color"
                href="https://floricultura.moradadapaz.com.br"
                target="_blank"
                rel="noreferrer"
              >
                Floricultura
              </a>
            </p>
            <p>
              <a
                className="icon-color"
                href="https://www.oquefazeremcasodemorte.com.br/turma-do-vilinha/"
                target="_blank"
                rel="noreferrer"
              >
                Turma do Vilinha
              </a>
            </p>
            <p>
              <a
                className="icon-color"
                href="https://www.oquefazeremcasodemorte.com.br"
                target="_blank"
                rel="noreferrer"
              >
                O que fazer em caso de morte
              </a>
            </p>
            <p style={{ marginBottom: 30 }}>
              <a
                className="icon-color"
                href="https://www.moradadapaz.com.br/trabalhe-conosco"
                target="_blank"
                rel="noreferrer"
              >
                Trabalhe Conosco
              </a>
            </p>
          </Col>
        </Row>
        <Row style={{ marginBottom: 80 }}>
          <Col className="footerBottom">
            <strong>&copy; {new Date().getFullYear()} Morada da Paz.</strong>{" "}
            Todos os direitos reservados.{" "}
            <a
              className="icon-color"
              href="https://www.moradadapaz.com.br/politica-de-privacidade"
              target="_blank"
              rel="noreferrer"
            >
              Política de Privacidade
            </a>
            <br />
            <small>
              Serpos Servicos Postumos LTDA - 04.811.849/0001-04 - R Presidente
              Joao Goulart, 2118 - Vila Torres Galvão - Paulista, Pernambuco -
              Brasil.
            </small>
          </Col>
        </Row>
      </>
    );
  };

  /**
   * Funções do modal de envio de boleto
   */
  const showModal = (value) => {
    setScreenModal(0);
    setIsModalVisible(true);
    setScreenModal(value);
  };

  /**
   * Função para baixar o arquivo na máquina do cliente
   */
  const downloadPdfFile = (pdf, counter) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `${contract}_vencimento_${new Date(
      objectsSelected[counter].VENCIMENTO
    ).toLocaleDateString()}_morada_da_paz`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  /**
   * Monta o iframe com o pdf na página do cliente
   */
  const handleOk = () => {
    // let pdfWindow = window.open("");

    // setLoading(true);

    let counter = 0;

    return pathToFiles.map((file) => {
      clientBoletosApi
        .base64({
          file,
        })
        .then((response) => {
          // pdfWindow.document.write(
          //   "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          //     encodeURI(response) +
          //     "'></iframe>"
          // );
          downloadPdfFile(response, counter);
          counter++;
          removeFile(file);
          // generateHistoric();
          setStates(1);
          setStateNow(1);
          setText("Informe o CPF do titular:");
          setPlaceholder("000.000.000-00");
          setQueryButton(true);
          setRecaptcha("");
          setDataContract([]);
          // setLoading(false);
          // setIsModalVisible(false);
          setContract("");
          setClient("");
          setQuantityInstallments(0);
          setValueInstallments(0);
          setDataParcelas([]);
          setPage(1);
          setRowsPerPage(5);
          // setScreenModal(0);
          setRowsSelected([]);
          setObjectsSelected([]);
          setSendButton(true);
          setContractCli({});
          setPathToFiles([]);
          setValueRadioButton(1);
          setEmailClient("");
        })
        .catch(() => {
          setStates(7);
          setLoading(false);
        });
    });
  };

  /**
   * Remove o arquivo salvo na pasta tmp do backend
   */
  const removeFile = (file) => {
    clientBoletosApi
      .delete({
        file,
      })
      .then((response) => response);
  };

  /**
   * Gerar histórico no protheus ao imprimir uma parcela do cliente
   */
  // const generateHistoric = async () => {
  //   Promise.all(
  //     objectsSelected.map((value) => {
  //       duplicateBilletApi.generateHistorico(
  //         {
  //           Contrato: value.CONTRATO,
  //           Nossonum: value.NOSSO_NUM,
  //           TipoContrt: value.TIPOCONTRT,
  //           Valor: value.VLRTOTAL,
  //         },
  //         {
  //           headers: {
  //             tenantId: `${value.EMPRESA},${value.FILIAL}`,
  //           },
  //         }
  //       );
  //     })
  //   ).then((response) => response);
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /**
   * Conteúdo do modal caso seja feito o download dos boeltos
   */
  const renderModalDownload = () => {
    return (
      <>
        <div className="title-modal">
          <CheckCircleOutlined style={{ fontSize: 50, color: "#71BF43" }} />
          <h2>Pronto! Agora é só imprimir ou compartilhar o seu boleto.</h2>
        </div>
        <br />
        <div className="title-modal">
          <CheckCircleOutlined
            style={{ fontSize: 50, color: "#FFF", marginRight: 20 }}
          />
          <p>
            <h3>Conheça nossas redes sociais!</h3>
            Acompanhe e siga o Morada da Paz também nas redes sociais e fique
            informado(a) sobre novidades, lançamentos e campanhas.
            {renderIconsSocialMediaModal()}
          </p>
        </div>
        <div className="footer-modal">
          <Button className="button-modal" size="large" onClick={handleCancel}>
            OK
          </Button>
        </div>
      </>
    );
  };

  const renderModalSuccess = () => {
    return (
      <>
        <div className="title-modal">
          <CheckCircleOutlined style={{ fontSize: 50, color: "#71BF43" }} />
          <h2>
            Envio feito com sucesso para <strong>{emailClient}</strong>!
            <br />
            Verifique o seu e-mail.
          </h2>
        </div>
        <br />
        <div className="title-modal">
          <CheckCircleOutlined
            style={{ fontSize: 50, color: "#FFF", marginRight: 20 }}
          />
          <p>
            <h3>Conheça nossas redes sociais!</h3>
            Acompanhe e siga o Morada da Paz também nas redes sociais e fique
            informado(a) sobre novidades, lançamentos e campanhas.
            {renderIconsSocialMediaModal()}
          </p>
        </div>
        <div className="footer-modal">
          <Button
            className="button-modal"
            size="large"
            onClick={() => {
              // generateHistoric();
              setIsSecondaryModalVisible(false);
              setStates(1);
              setStateNow(1);
              setText("Informe o CPF do titular:");
              setPlaceholder("000.000.000-00");
              setQueryButton(true);
              setRecaptcha("");
              setDataContract([]);
              setLoading(false);
              setIsModalVisible(false);
              setContract("");
              setClient("");
              setQuantityInstallments(0);
              setValueInstallments(0);
              setDataParcelas([]);
              setPage(1);
              setRowsPerPage(5);
              setScreenModal(0);
              setRowsSelected([]);
              setObjectsSelected([]);
              setSendButton(true);
              setContractCli({});
              setPathToFiles([]);
              setValueRadioButton(1);
              setEmailClient("");
            }}
          >
            OK
          </Button>
        </div>
      </>
    );
  };

  /**
   * Conteúdo do modal caso seja para enviar um e-mail
   */
  const renderModalSendMail = () => {
    return (
      <>
        <div className="title-modal">
          <h3>Para qual e-mail você deseja enviar?</h3>
        </div>
        <br />
        <Form name="form-send-mail" onFinish={sendMail} >
          <Form.Item rules={[{ required: true, message: "Forneça um e-mail válido" }]} name="customerEmail">
            <Input className="input-send-mail" placeholder="seu@email.com" />
          </Form.Item>
          <div className="footer-modal">
            <Button
              size="large"
              danger
              style={{ marginRight: 10, borderRadius: 5 }}
              onClick={handleCancel}
              disabled={loading}
            >
              <strong>CANCELAR</strong>
            </Button>
            <Button loading={loading} className="button-modal" size="large" htmlType="submit">
              <strong>ENVIAR</strong>
            </Button>
          </div>
        </Form>
      </>
    );
  };

  useEffect(() => {
    const scriptHuggy = document.createElement("script");
    scriptHuggy.text = `var $_Huggy = { defaultCountry: '+55', uuid: '7a461005-8eb1-4bb0-aa91-7d323c0204e6' , company: '325478' }; (function(i,s,o,g,r,a,m){ i[r]={context:{id:'5a3971a7d2146c246940b2a321f77142'}};a=o;o=s.createElement(o); o.async=1;o.src=g;m=s.getElementsByTagName(a)[0];m.parentNode.insertBefore(o,m); })(window,document,'script','https://js.huggy.chat/widget.min.js','pwz');`;
    document.body.appendChild(scriptHuggy);
  }, []);

  return (
    <>
      <Helmet>
        <title>2ª via de boletos | Morada da Paz</title>
        <link id="favicon" rel="icon" type="image/x-icon" href={favicon} />

        {/* AdOpt */}
        <meta
          name="adopt-website-id"
          content="ac856dca-aa6d-47fb-a2d8-91d42a2fa6f1"
        />
        <script
          src="//tag.goadopt.io/injector.js?website_code=ac856dca-aa6d-47fb-a2d8-91d42a2fa6f1"
          class="adopt-injector"
        ></script>
      </Helmet>
      <Alert
        message="Conheça o Clube de Descontos Morada da Paz. Uma plataforma com benefícios e vantagens exclusivas para você."
        type="info"
        showIcon
        action={
          <Button type="primary" href="https://bit.ly/ClubeMorada">
            Cadastre-se
          </Button>
        }
        closable
      />
      <header>
        <div className="header">
          <div className="logoImg">
            <a
              href="https://www.moradadapaz.com.br/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logo} width={isMobile() ? 180 : 200} />
            </a>
          </div>

          <div className="headersIcons">{renderSubMenuHeader()}</div>
        </div>

        <div className="content">
          <img src={capa} />
          <div className="content-div">
            <div className="title">2ª via de boletos</div>
            <div className="separator"></div>
            {isMobile() ? null : <br />}
            <div className="sub-title">
              <span className="sub-title-text">
                Aqui você pode emitir a 2º via de
              </span>{" "}
              {isMobile() ? <br /> : null}
              <span className="sub-title-text">
                boletos e consultar seus débitos com
              </span>
              {isMobile() ? <br /> : null}{" "}
              <span className="sub-title-text">o Morada da Paz.</span>
            </div>
            {loading ? (
              <>
                <div className="loading-centar">
                  <img src={loadingSVG} alt="loading" height={80} />
                </div>
                <h2 className="text-loading">Por favor, aguarde...</h2>
              </>
            ) : (
              <div>
                <div className="content-body">
                  <>
                    <div className="timeline">{timeLine()}</div>
                    <div className="form">{renderStatePage()}</div>
                  </>
                </div>
                <div className="screen">
                  <div className="message-grid">
                    <h2 className="titleApp">Aplicativo Morada da Paz</h2>
                    <p className="subTitleApp">Pague seus boletos, consulte os benefícios dos seus produtos e serviços, e muito mais.</p>
                  </div>
                  <div className="store-link">
                    <p className="subTitleApp2"><b>Baixe agora o Aplicativo Morada, disponível para Android e iOS:</b></p>
                    <div className="linksApp">
                      <a href="https://play.google.com/store/apps/details?id=br.com.moradadapaz.moradacustomers" target="_blank" rel="noreferrer">
                        <img
                          className="android"
                          src={linkAndroid}
                        />
                      </a>
                      <a href="https://apps.apple.com/br/app/morada-da-paz/id6467040919" target="_blank" rel="noreferrer">
                        <img
                          className="ios"
                          src={linkIos}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div class="container-fluid">
              <div class="footer">{renderFooter()}</div>
            </div>
          </div>
        </div>

        <Modal
          title={false}
          open={isModalVisible}
          footer={false}
          closable={false}
          centered
        >
          {screenModal === 1 ? renderModalDownload() : renderModalSendMail()}
        </Modal>
        <Modal
          title={false}
          visible={isSecondaryModalVisible}
          footer={false}
          closable={false}
        >
          {renderModalSuccess()}
        </Modal>
      </header>
    </>
  );
}

export default DuplicateBillet;
