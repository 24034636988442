import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";
import { Button, Col, Divider, Form, Input, Row, Spin, Steps, DatePicker, message } from "antd";
import { useState } from "react";
import { ContractApprover } from "../../../../utils/apiContractApprover";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { LoadingOutlined } from '@ant-design/icons';
import locale from "antd/es/date-picker/locale/pt_BR";
import { useSelector } from "react-redux";
import moment from "moment";
import { AttachmentsViewer } from "./components/viwerDocuments";
import { toInteger } from "lodash";



function PlanContractDetail(props) {
  const [dataSource, setDataSource] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [datePayment, setDatePayment] = useState("");
  const [currentAttachment, setCurrentAttachment] = useState([]);


  const signin = useSelector((state) => state.signin);

  const history = useHistory();

  const {
    contract,
    branch,
    company } = useParams();

  const breadcrumb = [
    {
      title: "Comercial",
      path: props.modulePath,
    },
    {
      title: "Aprovação de Contratos de Plano",
      path: props.modulePath + "/plan-contract-approval",
    },
    {
      title: "Aprovação Contrato: " + contract,
      path: props.modulePath + "/plan-contract-approval",
    },
  ];

  const getDetailsContract = async () => {
    setLoading(true)
    try {
      const { data } = await ContractApprover.getContractDetail(`${company},${branch}`, contract);
      setDataSource(data)

      if (data.arquivos.length > 0) {
        var docs = data.arquivos.map(data => {
          return {
            name: data['nome_arquivo'],
            url: data['caminho_arquivo'],
          };
        });

        setCurrentAttachment(docs)
      }


      setLoading(false)

    } catch (error) {
      message.error("Não foi buscar as informações do contrato.")
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetailsContract()
  }, [])

  // passos para finalização
  const steps = [
    {
      title: 'Cliente',
    },
    {
      title: 'Dados do plano',
    },
    {
      title: 'Dependentes',
    },
    {
      title: 'Documentos',
    },
    {
      title: 'Financeiro',
    },
    {
      title: 'Finalização',
    },
  ];

  const handleClick = (attachment) => {
    setCurrentAttachment(attachment);
  };

  // const handleDowload = () => {
  //   saveAs(attachmentUrl, selectedAttachment.title);
  // };

  //geração do itens
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  function UserInfoForm({ cliente = {} }) {
    const {
      codigo = "",
      tipo = "",
      cpf = "",
      nome = "",
      rg = "",
      origem = "",
      estado_civil = "",
      data_nascimento = "",
      cep = "",
      estado = "",
      municipio = "",
      bairro = "",
      endereco = "",
      numero = "",
      referencia = "",
      complemento = "",
      telefone_1 = "",
      telefone_2 = "",
      email = "",
      notificação = "",
      genero = "",
    } = cliente;

    return (
      <Form
        layout="vertical"
        onFinish={() => {
          /* Implementar lógica de finalização */
        }}
      >
        {/* Identificação */}
        <Divider>Identificação</Divider>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="codigo" label="Código">
              <Input readOnly defaultValue={codigo} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="tipo" label="Tipo">
              <Input readOnly defaultValue={tipo} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="cpf" label="CPF">
              <Input readOnly defaultValue={cpf} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="rg" label="RG">
              <Input readOnly defaultValue={rg} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="origem" label="Origem">
              <Input readOnly defaultValue={origem} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="nome" label="Nome">
              <Input readOnly defaultValue={nome} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="genero" label="Gênero">
              <Input readOnly defaultValue={genero} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="estado_civil" label="Estado Civil">
              <Input readOnly defaultValue={estado_civil} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="data_nascimento" label="Data de Nascimento">
              <Input readOnly defaultValue={moment(data_nascimento, "YYYYMMDD").format("DD/MM/YYYY")} />
            </Form.Item>
          </Col>
        </Row>

        {/* Contato */}
        <Divider>Contato</Divider>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="telefone_1" label="Telefone 1">
              <Input readOnly defaultValue={telefone_1} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="telefone_2" label="Telefone 2">
              <Input readOnly defaultValue={telefone_2} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="email" label="Email">
              <Input readOnly defaultValue={email} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="notificacao" label="Recebe E-mail">
              <Input readOnly defaultValue={notificação == true ? "Sim" : "Não"} />
            </Form.Item>
          </Col>
        </Row>

        {/* Endereço */}
        <Divider>Endereço</Divider>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="cep" label="CEP">
              <Input readOnly defaultValue={cep} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="estado" label="Estado">
              <Input readOnly defaultValue={estado} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="municipio" label="Município">
              <Input readOnly defaultValue={municipio} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="bairro" label="Bairro">
              <Input readOnly defaultValue={bairro} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="endereco" label="Endereço">
              <Input readOnly defaultValue={endereco} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="numero" label="Número">
              <Input readOnly defaultValue={numero} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="referencia" label="Referência">
              <Input readOnly defaultValue={referencia} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="complemento" label="Complemento">
              <Input readOnly defaultValue={complemento} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button type="primary" onClick={() => next()}>
            Próximo
          </Button>
        </Row>
      </Form>

    );
  };

  function PlanDetailsForm({ dados_plano = {}, info_pagamento = {} }) {
    const [form] = Form.useForm();
    const [buttonLoading, setButtonLoading] = React.useState(false);

    const {
      contrato = "",
      cod_produto = "",
      nome_produto = "",
      valor_total = "",
      taxa_titular = "",
      taxa_dependetes = "",
      carencia = "",
      data_carencia = "",
      reducao_carencia = "",
      reducao_taxa_adesao = "",
      declinio = "",
      obs = "",
    } = dados_plano;

    const is_migration = info_pagamento.forma_pagamento == '5'

    React.useEffect(() => {
      form.setFieldsValue({
        contrato,
        cod_produto,
        nome_produto,
        valor_total: parseFloat(valor_total).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
        taxa_titular: parseFloat(taxa_titular).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
        taxa_dependetes: parseFloat(taxa_dependetes).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
        carencia,
        data_carencia: data_carencia ? moment(data_carencia, "YYYYMMDD").format("DD/MM/YYYY") : null,
        reducao_carencia,
        reducao_taxa_adesao,
        declinio,
        obs,
      });
    }, [dados_plano, form]);

    const handleFinish = (values) => {
      setButtonLoading(true);

      ContractApprover.updatedContract(
        `${company},${branch}`,
        contract,
        valor_total,
        taxa_titular,
        taxa_dependetes,
        parseInt(values.carencia),
        moment(values.data_carencia, "DD/MM/YYYY").format("YYYYMMDD"),
        parseInt(values.reducao_carencia)
      )
        .then(() => {
          setButtonLoading(false);
          next();
        })
        .catch((error) => {
          console.log(error);
          message.error("Não foi possível atualizar as informações do plano.")
          setButtonLoading(false);
        });
    };

    const handleReducaoChange = (e) => {
      const reducao = parseFloat(e.target.value) || 0;
      const carenciaAtual = 180;

      // Aplica a redução percentual
      const novoValor = carenciaAtual - (carenciaAtual * (reducao / 100));

      const novaData = moment(dados_plano.emissao, "YYYYMMDD").add(Math.round(novoValor), "days").format("DD/MM/YYYY")

      form.setFieldsValue({ carencia: Math.round(novoValor), data_carencia: novaData }); // Atualiza o campo "carencia"


    };

    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        <Divider>Informações do Plano</Divider>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="contrato" label="Contrato">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="cod_produto" label="Código do Produto">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="nome_produto" label="Nome do Produto">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="valor_total" label="Valor Total">
              <Input
                readOnly
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="taxa_titular" label="Taxa do Titular">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="taxa_dependetes" label="Taxa dos Dependentes">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="carencia" label="Carência (dias)" rules={[
              {
                required: true,
                message: "Informe a carência",
              }, {
                validator: (_, value) => {
                  if (value) {
                    if (value < 0) {
                      return Promise.reject("Informe um valor valido");
                    }
                  }
                  return Promise.resolve();
                },
              },]}>
              <Input type="number" readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="data_carencia" label="Data da Carência">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="reducao_carencia" label="Redução de Carência" rules={[
              {
                required: true,
                message: "Informe a redução da carência",
              },
              {
                validator: (_, value) => {
                  if (value) {
                    if (value < 0 || value > 100) {
                      return Promise.reject("Informe um valor valido");
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}>
              <Input type="number" readOnly={!is_migration} onChange={handleReducaoChange} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="reducao_taxa_adesao" label="Redução da Taxa de Adesão">
              <Input readOnly type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="declinio" label="Declínio">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Form.Item name="obs" label="Observações">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={[16, 16]}>
          <Button onClick={() => prev()}>Voltar</Button>
          <Button type="primary" htmlType="submit" loading={buttonLoading} style={{ margin: "0 8px" }}>
            Próximo
          </Button>
        </Row>
      </Form>
    );
  }


  function DependentsForm({ dependentes = [""] }) {
    const [form] = Form.useForm();
    const [buttonLoading, setButtonLoading] = React.useState(false);

    const formatDate = (dateString) => {
      if (!dateString) return "";
      const [year, month, day] = [dateString.slice(0, 4), dateString.slice(4, 6), dateString.slice(6, 8)];
      return `${day}/${month}/${year}`;
    };

    React.useEffect(() => {
      const initialValues = dependentes.reduce((acc, dependente, index) => {
        acc[`nome_${index}`] = dependente.nome || "";
        acc[`parentesco_${index}`] = dependente.parentesco || "";
        acc[`data_nascimento_${index}`] = dependente.data_nascimento
          ? moment(dependente.data_nascimento, "YYYYMMDD")
          : null;
        acc[`carencia_${index}`] = dependente.carencia || "";
        return acc;
      }, {});

      form.setFieldsValue(initialValues);
    }, [dependentes, form]);

    const handleFinish = (values) => {
      const dependenteIndices = Object.keys(values)
        .filter((key) => key.startsWith("nome_"))
        .map((key) => key.split("_")[1]);

      const groupedDependentes = dependenteIndices.map((index) => ({
        item: dependentes[index]?.item,
        carencia: parseInt(values[`carencia_${index}`], 10),
      }));

      setButtonLoading(true);

      ContractApprover.updatedDependentes(`${company},${branch}`, contract, groupedDependentes)
        .then(() => {
          setButtonLoading(false);
          next();
        })
        .catch((error) => {
          console.log(error);
          message.error("Não foi possível atualizar as informações dos dependentes.")
          setButtonLoading(false);
        });
    };

    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        {dependentes.map((dependente, index) => (
          <React.Fragment key={index}>
            <Divider>Dependente {index + 1}</Divider>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name={`nome_${index}`} label="Nome">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name={`parentesco_${index}`} label="Parentesco">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name={`data_nascimento_${index}`} label="Data de Nascimento">
                  <DatePicker
                    readOnly
                    locale={locale}
                    format="DD/MM/YYYY"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item name={`carencia_${index}`} label="Carência (dias)" rules={[
                  {
                    required: true,
                    message: "Informe a carência",
                  }, {
                    validator: (_, value) => {
                      if (value) {
                        if (value < 0) {
                          return Promise.reject("Informe um valor valido");
                        }
                      }
                      return Promise.resolve();
                    },
                  },]}>
                  <Input type="number" />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        ))}
        <Row justify="end" gutter={[16, 16]}>
          <Button onClick={() => prev()}>Voltar</Button>
          <Button type="primary" htmlType="submit" loading={buttonLoading} style={{ margin: "0 8px" }}>
            Próximo
          </Button>
        </Row>
      </Form>
    );
  }

  function PaymentInfoForm({ info_pagamento = {} }) {
    const {
      cod_empresa = "",
      nome_empresa = "",
      forma_pagamento = "",
      dia_pagamento = "",
      data_primeiro_venc = "",
      primeiro_pagamento = "",
      fatura_digital = "",
    } = info_pagamento;

    const formatDate = (dateString) => {
      if (!dateString) return "";
      const [year, month, day] = [dateString.slice(0, 4), dateString.slice(4, 6), dateString.slice(6, 8)];
      return `${day}/${month}/${year}`;
    };

    const formatPaymentMethod = (method) => {
      const methods = {
        "1": "Carnê",
        "2": "Cartão de Débito",
        "3": "Cartão de Crédito",
        "4": "Desconto em Folha",
        "5": "Migração",
        "6": "Renovação",
        "7": "Plano 100",
        "8": "Boleto Bancário",
        "9": "Convênio/Carnê",
        "0": "Campanha",
      };
      return methods[method] || "Indefinido";
    };


    const formatFirstMethod = (method) => {
      const methods = {
        "1": "Dinheiro",
        "2": "Cartão",
        "3": "Cheque",
        "4": "Nenhum",
        "5": "E-Commerce",
        "6": "Pix",
      };
      return methods[method] || "Indefinido";
    }

    return (
      <Form
        layout="vertical"
        onFinish={() => {
          /* Implementar lógica de finalização */
        }}
      >
        <Divider>Informações de Pagamento</Divider>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="cod_empresa" label="Código da Empresa">
              <Input readOnly defaultValue={cod_empresa} />
            </Form.Item>
          </Col>
          <Col xs={18}>
            <Form.Item name="nome_empresa" label="Nome da Empresa">
              <Input readOnly defaultValue={nome_empresa} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="forma_pagamento" label="Forma de Pagamento">
              <Input readOnly defaultValue={formatPaymentMethod(forma_pagamento)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="dia_pagamento" label="Dia de Pagamento">
              <Input readOnly defaultValue={dia_pagamento} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="data_primeiro_venc" label="Data do Primeiro Vencimento">
              <Input readOnly defaultValue={formatDate(data_primeiro_venc)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="primeiro_pagamento" label="Forma de pagamento da primeira parcela">
              <Input readOnly defaultValue={formatFirstMethod(primeiro_pagamento)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="fatura_digital" label="Fatura digital">
              <Input readOnly defaultValue={fatura_digital ? "Sim" : "Não"} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={[16, 16]}>
          <Button onClick={() => prev()}>
            Voltar
          </Button>
          <Button type="primary" style={{ margin: '0 8px' }} onClick={() => next()}>
            Próximo
          </Button>
        </Row>
      </Form>
    );
  }

  function Finaly() {
    return (
      <Form
        layout="vertical"
        onFinish={handleFinish}
      >
        {/* Finalização */}
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Form.Item name="first_vencimento" label="Primeiro vencimento" rules={[
              {
                required: true,
                message: "Informe a data da carência",
              },]}>
              <DatePicker
                locale={locale}
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Button onClick={() => prev()}>
            Voltar
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Ativar
          </Button>
        </Row>
      </Form>
    );
  }


  async function handleFinish(values) {
    try {

      setLoading(true);

      await ContractApprover.activeContract(`${company},${branch}`, contract, values.first_vencimento.format('YYYYMMDD'), signin.user.name);

      message.success("Contrato ativado com sucesso.")

      setLoading(false);

      history.goBack()

    } catch (e) {
      setLoading(false);
      message.error("Não foi possível ativar o contrato. " + e)
    }
  }


  // Componente principal que recebe um número
  const ParentComponent = ({ number }) => {
    if (number < 0) return <div>O número deve ser positivo!</div>;
    if (number == 0) {
      return (
        <UserInfoForm cliente={dataSource.cliente} />);
    }


    if (number == 1) {
      return (
        <PlanDetailsForm dados_plano={dataSource.dados_plano} info_pagamento={dataSource.info_pagamento} />
      );
    }

    if (number == 2) {
      return (
        <DependentsForm dependentes={dataSource.depedentes} />
      );
    }

    if (number == 3) {
      return (
        <>
          <Col>
            <AttachmentsViewer attachments={currentAttachment} />
            <Row justify="end" gutter={[16, 16]} style={{ marginTop: 10 }}>
              <Button onClick={() => prev()}>
                Voltar
              </Button>
              <Button type="primary" style={{ margin: '0 8px' }} onClick={() => next()}>
                Próximo
              </Button>
            </Row>
          </Col>
        </>
      )
    }

    if (number == 4) {
      return (
        <PaymentInfoForm info_pagamento={dataSource.info_pagamento} />
      );
    }

    if (number == 5) {
      return (
        <Finaly />
      );

    }



    return (<div>

    </div>)
  };


  return (
    <>
      <Helmet>
        <title>Aprovação de Contratos de Plano - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensMenu}
            modulePath={props.modulePath}
            selectedMenu="1"
          />
        }
        breadcrumb={breadcrumb}
      >
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            <h1 style={{ color: "gray" }}>Carregando...</h1>
          </div>
        ) : (
          <>
            <Steps current={current} items={items} />
            <div
              style={{
                marginTop: 24,
              }}
            >
              {/* Um único Form engloba todo o conteúdo */}
              <Col>
                <ParentComponent number={current} dataSource={dataSource} />
              </Col>
            </div>
          </>
        )}
      </Main>
    </>
  );
}

export default PlanContractDetail;
